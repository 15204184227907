import clsx from 'clsx';
import {
    Children,
    createContext,
    PropsWithChildren,
    ReactElement,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import stringToColor from '~/utils/stringToColor';

interface Props {
    className?: string;
    src?: string;
    name?: string;
    size?: 'default' | 'small' | 'large' | 'xl';
}

export default function Avatar(props: Props): ReactElement {
    const { className, src, name, size = 'default' } = props;

    const initials =
        name
            ?.toUpperCase()
            .split(' ')
            .map((part) => part[0])
            .filter((l) => !!l)
            .slice(0, 2)
            .join('') ?? '';

    const color = useMemo(() => stringToColor(name ?? ''), [name]);

    return (
        <div
            className={clsx(
                'inline-flex justify-center items-center relative align-middle rounded-full overflow-hidden font-bold',
                !src && 'border',
                size === 'default' && 'w-8 h-8 text-base',
                size === 'small' && 'w-6 h-6 text-sm',
                size === 'large' && 'w-12 h-12 text-4xl',
                size === 'xl' && 'w-24 h-24 text-6xl',
                color.isLight() ? 'text-black' : 'text-white',
                className,
            )}
            title={name}
            style={{ backgroundColor: color.hex(), borderColor: color.darken(0.2).hex() }}
        >
            {src ? (
                <img
                    src={src}
                    alt={name}
                    className="w-full h-full object-cover absolute top-0 left-0"
                />
            ) : null}
            {!src && initials ? <span>{initials}</span> : null}
        </div>
    );
}

Avatar.Stack = function AvatarStack(props: PropsWithChildren<unknown>): ReactElement {
    const arrChildren = Children.toArray(props.children);
    const tooltip = arrChildren
        .map((c) => (c as ReactElement)?.props?.name)
        .filter((name) => !!name)
        .reverse()
        .join(', ');

    return (
        <div className="flex flex-row-reverse justify-end" title={tooltip}>
            {Children.map(props.children, (child) => (
                <span className="-mr-2">{child}</span>
            ))}
        </div>
    );
};
