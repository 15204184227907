import color from 'color';

export default function stringToColor(string: string): color {
    let hash = 0;

    if (string.length === 0) return color('#ffffff');

    for (var i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }

    let colorString: `#${string}` = '#';

    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 255;
        colorString += ('00' + value.toString(16)).substr(-2);
    }

    return color(colorString);
}